import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

const BlogTemp = ({ data, location, pageContext }) => (
  <Layout>
    <SEO
      pagetitle="インフォメーション"
      pagedesc="ソリューション、製品、会社等に関するインフォメーションをお知らせします。"
      pagepath={location.pathname}
    />

    <section className="children">
      <h2 className="sr-only">children</h2>
      <figure>
        <Img
          fluid={data.children.childImageSharp.fluid}
          alt="micro:bitを中心にプログラミング教育教材を提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>
    <br />

    <section className="feature">
      <div className="container">
        <h2 className="bar">
          Our <span>Information</span>
        </h2>
      </div>
    </section>

    <section className="bloglist">
      <div className="container">
{/*         <h1 className="bar">Infomation</h1> */}
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/jp/info/post/${node.slug}/`}>
                <figure>
                  <Img
                    fluid={node.eyecatch.fluid}
                    alt={node.eyecatch.description}
                    style={{ height: "100%" }}
                    loading="eager"
                    durationFadeIn={100}
                  />
                </figure>
                <h3>{node.title}</h3>
              </Link>
            </article>
          ))}
        </div>

        <ul className="pagenation">
          {!pageContext.isFirst && (
            <li className="prev">
              <Link
                to={
                  pageContext.currentPage === 2
                    ? `/jp/info/`
                    : `/jp/info/${pageContext.currentPage - 1}/`
                }
                rel="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            </li>
          )}
          {!pageContext.isLast && (
            <li className="next">
              <Link to={`/jp/info/${pageContext.currentPage + 1}/`} rel="next">
                <span>次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    children: file(relativePath: { eq: "children.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`

export default BlogTemp
